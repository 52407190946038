@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Noto Color Emoji", "Segoe UI Emoji", sans-serif;
}
body,
main {
  @apply bg-[#F9F9F9];
}

.MuiButtonBase-root.Mui-selected .MuiTypography-root {
  font-weight: 500;
  fint-family:'inter'
}
.MuiInputLabel-root {
  font-size: 14px !important;
}

.main-paper {
  @apply bg-white p-6 rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.1)];
}

.start-section {
  background-image: url("./assets/images/start-bg.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.start-section-list {
  @apply flex items-center flex-wrap font-medium;
}
.start-section-list li {
  @apply text-[15px];
}
.start-section-list li:first-child {
  @apply list-none;
}
.start-section-list li:not(:first-child) {
  @apply ml-8;
  position: relative;
}
.start-section-list li:not(:first-child)::before {
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  content: " ";
  background-image: url("./assets/images/circle.svg");
  background-size: 6px 6px;
  top: 50%;
  left: -16px;
  transform: translate(-50%, -50%);
}

.analysis-box:not(:last-child) {
  @apply border-b border-[#E8E8E8];
}
.analysis-box-bottom--list li {
  @apply flex items-center mb-2 text-[13px] text-[#585757];
}
.analysis-box-bottom--list li svg {
  @apply mr-2 fill-[#969696] w-[15px];
}

.circular-progress-bg {
  @apply absolute z-[1] right-0;
}
.circular-progress-bg svg {
  @apply text-[#FCDDEC];
}

.circular-progress-fg {
  @apply relative z-[2];
}

.MuiFormHelperText-root {
  @apply m-0 leading-3 mt-2 text-red-500;
}

/* Country Code */
.react-tel-input {
  @apply flex w-full col-span-2;
}
.react-tel-input .form-control {
  @apply flex-1 w-full py-[8.5px] h-[1.4375em] focus:border-[#D4024B] focus:shadow-[0_0_0_1px_#D4024B] box-content pr-[14px] placeholder:text-black/[0.6] text-base;
}

.react-tel-input .search {
  @apply flex items-center;
  padding-right: 10px !important;
}
.react-tel-input .search .search-box {
  @apply flex-1;
}

.react-tel-input .form-control:focus + .special-label {
  @apply text-[#D4024B!important];
}

.MuiButtonBase-root.MuiButton-contained.MuiButton-root.Mui-disabled {
  @apply opacity-75 grayscale text-white;
}

.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paperAnchorLeft {
  @apply bg-[#102132] text-white;
}

.section-title-tpg {
  @apply !text-lg;
}

.report-title {
  @apply text-[15px];
}

/* .add-section-body .account-preview:nth-of-type(3) {
  @apply lg:col-span-2 xl:col-span-1;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}